<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import { computedRef, getSourceFacilityCode } from "../../utils/composables";
import { useAverageWeeklyDemand } from "../../store/averageWeeklyDemand.pinia";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { usePartBom } from "../../store/partBom.pinia";
import { isRegionUS } from "../../utils/variables";

export default {
  name: "FinishedGoodsPartAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    const averageWeeklyDemandStore = useAverageWeeklyDemand();
    const partBomStore = usePartBom();

    const averageWeeklyDemandParams = computedRef(() => {
      return {
        query: {
          part_id: props.node.part.id,
        },
      };
    });

    const {
      total: totalAvgWeekly,
      results: awdResults,
      facilities: awdFacilities,
      isPending: isPendingAWD,
    } = averageWeeklyDemandStore.bundledAWD(averageWeeklyDemandParams);

    const propNodePart = computed(() => {
      return props.node?.part;
    });
    const sourceFacilityCode = getSourceFacilityCode(propNodePart);

    const partBomId = computed(() => props?.node?.part?.id ?? null);

    const { isPending, bomPriceRollup } =
      partBomStore.getBomPriceRollup(partBomId);

    const annualForecast = computed(() => {
      return {
        annual_forecast: props.node.part.annual_forecast / 4,
      };
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        uom: props.isPending.part,
        partType: props.isPending.part,
        commodity: props.isPending.part,
        typeCode: props.isPending.part,
        sourceFacilityCode: isPendingAWD.value,
        facilityDemand: isPendingAWD.value,
        averageWeeklyDemand: isPendingAWD.value,
        bomPriceRollUp: isPending.value,
        totalAvgWeeklyDemand: isPendingAWD.value,
        annualForecast: props.isPending.part,
      };
    });

    const entities = computed(() => {
      return {
        part: props.node.part,
        uom: props.node.part.uom,
        partType: props.node.part.part_type,
        commodity: props.node.part.commodity,
        typeCode: props.node.part.type_code,
        sourceFacilityCode: sourceFacilityCode.value,
        facilityDemand: awdFacilities.value, // arrayItem
        averageWeeklyDemand: awdResults.value, // arrayItem
        bomPriceRollUp: bomPriceRollup.value,
        totalAvgWeeklyDemand: totalAvgWeekly.value,
        annualForecast: annualForecast.value,
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow(
          "Material Part",
          [
            entityRowField("part", "part_number", { label: "Part Number" }),
            entityRowField("part", "description", { label: "Description" }),
            !isRegionUS
              ? entityRowField("part", "german_description", {
                  label: "German Description",
                })
              : null,
            entityRowField("part", "status", { label: "Status" }),
            entityRowField("uom", "description", { label: "UOM Description" }),
            entityRowField("partType", "description", {
              label: "Part Type Description",
            }),
            entityRowField("commodity", "description", {
              label: "Commodity Description",
            }),
            entityRowField("typeCode", "description", {
              label: "Type Code Description",
            }),
            entityRowField("part", "source_code", { label: "Source Code" }),
            entityRowField("sourceFacilityCode", "code", {
              label: "Source Facility",
            }),
            entityRowField("part", "chain_of_custody", {
              label: "Chain of Custody",
            }),
            entityRowField("part", "variance", {
              label: "Variance",
            }),
            entityRowField("part", "mrp", { label: "MRP", type: "checkbox" }),
            entityRowField("part", "order_qty", {
              label: "Order Quantity",
            }),
            entityRowField("annualForecast", "annual_forecast", {
              label: "AQD",
            }),
            entityRowField("part", "carb_compliant", {
              label: "Carb Compliant",
              type: "checkbox",
            }),
            entityRowField("part", "weight", { label: "Weight" }),
            isRegionUS
              ? entityRowField("bomPriceRollUp", "price", {
                  label: "BOM Price Rollup",
                })
              : null,
            entityRowField("part", "production_notes", {
              label: "Production Notes",
              type: "textarea",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
            entityRowField("part", "production_alert", {
              label: "Production Alert",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
          ].filter((item) => item !== null)
        ),
        entityRow(
          "Average Weekly Demand",
          [
            entityRowField("averageWeeklyDemand", null, {
              type: "averageWeeklyDemand",
              includeEntities: true,
            }),
          ],
          {
            showLabel: true,
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
